import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7f0cded9&scoped=true"
import script from "./index.ts?vue&type=script&lang=ts&external"
export * from "./index.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f0cded9",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GkvrButton: require('/app/components/gkvr-button/GkvrButton.ts').default})
