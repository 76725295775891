import { Component, Vue } from 'vue-property-decorator'
import { AccountService } from '~/services'
import { TokenService } from '~/services/token.service'
import { Roles, Routes } from '~/types'

@Component({
  components: {
  },
  layout: 'default',
  middleware: 'notAuthenticated'
})
export default class App extends Vue {
  // --------------------------------------------------------------------------
  // [Private] Fields
  // --------------------------------------------------------------------------

  // Credentials
  email: string = ''
  password: string = ''

  // Error properties
  error: boolean = false

  processing: boolean = false
  // --------------------------------------------------------------------------
  // [Public] Constructor
  // --------------------------------------------------------------------------

  // eslint-disable-next-line no-useless-constructor
  constructor () {
    super()
  }

  // --------------------------------------------------------------------------
  // [Public] Accessors
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Public] Methods
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // [Private] Event Handlers
  // --------------------------------------------------------------------------

  async onAuthenticationFormSubmit () {
    this.processing = true

    const passValidation: boolean = await this.isFormValid()

    if (passValidation) {
      // if admin email and password the attempt login

      try {
        const authenticatedCredentials = await AccountService.authenticateUser(this.email, this.password)

        const isAuthenticated = authenticatedCredentials ? true : null

        // Store credentials in cookie
        TokenService.saveCredentials(authenticatedCredentials.accessToken, authenticatedCredentials.refreshToken)

        // // update application state to authenticatied
        this.$store.commit('setAuthenticated', isAuthenticated)

        const decoded: any = TokenService.decodeJwt(authenticatedCredentials.accessToken)

        const accountData = {
          authorities: decoded.authorities
        }

        await this.$store.commit('setAuthorities', accountData)

        await this.$store.dispatch('loadAccountInfo')

        const role = decoded.authorities.find((elem: string) => !elem.includes(':'))

        if (role !== undefined) {
          switch (role) {
            case Roles.ROLE_ADMIN:
              this.$router.push(Routes.MANAGE_USERS)
              break
            case Roles.ROLE_ACCOUNTANT:
              this.$router.push(Routes.MANAGE_VOUCHER)
              break
            case Roles.ROLE_CSR:
              this.$router.push(Routes.MANAGE_PROMO_CODES)
              break
            case Roles.ROLE_GRACE_PARTNER_ADMIN:
              this.$router.push(Routes.MANAGE_REWARDS)
              break
            case Roles.ROLE_PARTNER_ADMIN:
              this.$router.push(Routes.MANAGE_VOUCHER)
              break
            case Roles.ROLE_PARTNER_STAFF:
              this.$router.push(Routes.APPROVE_VOUCHER)
              break
            default:
              this.$router.push(Routes.APPROVE_VOUCHER)
              break
          }
        }
      } catch (error: any) {
        console.log(error)
        this.error = true
      }
    }
    this.processing = false
  }

  // --------------------------------------------------------------------------
  // [Private] Methods
  // --------------------------------------------------------------------------
  private isFormValid (): boolean {
    return (this as any).$refs.loginForm.validate().then((passedValidation: boolean) => {
      return passedValidation
    })
  }
  // --------------------------------------------------------------------------
  // [Private] Lifecycle Hooks
  // --------------------------------------------------------------------------
}
